<template>
  <b-card class="border">
    <div class="flex items-center mb-1 gap-6">
      <b-img :src="iconCard" />
      <div class="text-[14px] font-bold">
        {{ nameCard }}
      </div>
    </div>
    <div class="flex items-center ">
      <div class="text-[24px] pl-0 p-[10px] border-r-2 w-fit font-bold">
        {{ totalData }}
      </div>
      <div class="pl-[10px] grid gap-4">
        <div class="text-[#34A770]">
          {{ weeklyData }} Minggu Ini
        </div>
        <div class="ml-[5px] text-[#08A0F7]">
          {{ yesterdayData }} Kemarin
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    nameCard: {
      type: String,
      default: '',
    },
    iconCard: {
      type: String,
      default: '',
    },
    totalData: {
      type: Number,
      default: 0,
    },
    weeklyData: {
      type: Number,
      default: 0,
    },
    yesterdayData: {
      type: Number,
      default: 0,
    },
  },
}
</script>
