export const fieldsPartnerTable = [
  {
    key: 'name',
    label: 'Nama Partner',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'phone',
    label: 'Nomor',
    thClass: 'font-bold capitalize text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
  {
    key: 'last_active',
    label: 'Terakhir Aktif',
    thClass: 'font-bold text-black',
    class: 'align-middle text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
  },
]

export const black = 'gjhj'
