<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <div class="lg:w-[30%]">
          <BInputGroup class="wrapper-search mb-1">
            <template #prepend>
              <BInputGroupText style="border-right: 0">
                <b-img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                />
              </BInputGroupText>
            </template>
            <BFormInput
              v-model="search"
              placeholder="Cari berdasarkan Nama/No HP"
              style="border-left: 0"
              class="pl-0"
              @input="handleSearchPartner"
            />
          </BInputGroup>
        </div>
        <BOverlay
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            id="scroll"
            ref="scroll"
            :items="items"
            :fields="fieldsPartnerTable"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            sticky-header="500px"
            class="mt-1"
            @scroll.native="handleScroll"
          />
        </BOverlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fieldsPartnerTable } from '../config'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldsPartnerTable,
      search: '',
    }
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.$emit('getNextData')
      }
    },
    handleSearchPartner() {
      this.$emit('handleSearchPartner', this.search)
    },
  },
}
</script>
